import { createSelector } from 'reselect';

const selectBillingEntities = (state) => state.billingNew.billingEntities;
const selectBillableEntities = (state) => state.billingNew.billableEntities;
const selectInvoices = (state) => state.billingNew.invoices;
const selectLastInvoiceDate = (state) => state.billingNew.lastInvoiceDate;
const selectBillingPlans = (state) => state.billingNew.billingPlans;
const selectBillingSubscriptions = (state) =>
    state.billingNew.billingSubscriptions;

const selectIuBillingEntity = createSelector(
    selectBillingEntities,
    (billingEntities) =>
        billingEntities?.find((entity) => entity.producerIds.length === 0)
);
const selectIuBillableEntity = createSelector(
    selectBillableEntities,
    (billableEntities) =>
        billableEntities?.find((entity) => entity.consumerIds.length === 0)
);

export {
    selectBillingEntities,
    selectBillableEntities,
    selectInvoices,
    selectLastInvoiceDate,
    selectBillingPlans,
    selectBillingSubscriptions,
    selectIuBillingEntity,
    selectIuBillableEntity,
};
