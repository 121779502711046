import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { selectSelectedOperation } from '../../redux/selectors/operationSelectors';
import {
    setBillingEntities,
    setBillingPlans,
    setInvoices,
    setLastInvoiceDate,
    setBillingSubscriptions,
    setError,
    setBillableEntities,
} from '../../redux/actions';
import {
    CREATE_BILLING_ENTITY,
    CREATE_BILLING_PLAN,
    CREATE_BILLING_SUBSCRIPTION,
    GET_BILLING_ENTITIES,
    GET_BILLING_PLANS,
    GET_BILLING_SUBSCRIPTIONS,
    UPDATE_BILLING_ENTITY,
    GET_INVOICES,
    GET_LAST_INVOICE_DATE,
    DOWNLOAD_INVOICE,
    UPDATE_BILLING_PLAN,
    UPDATE_BILLING_SUBSCRIPTION,
    GET_BILLABLE_ENTITIES,
    UPDATE_BILLABLE_ENTITY,
    CREATE_BILLABLE_ENTITY,
} from '../../redux/reducers/constants';

function* getBillingEntities(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        let billingEntities = yield call(
            billingGateway.getBillingEntities,
            operation.id
        );
        yield put(setBillingEntities(billingEntities));
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* getBillableEntities(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        let billableEntities = yield call(
            billingGateway.getBillableEntities,
            operation.id
        );
        yield put(setBillableEntities(billableEntities));
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* createBillingEntity(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.createBillingEntity,
            operation.id,
            action.entity
        );
        let billingEntities = yield call(
            billingGateway.getBillingEntities,
            operation.id
        );
        yield put(setBillingEntities(billingEntities));
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* updateBillingEntity(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.updateBillingEntity,
            operation.id,
            action.entity
        );
        let billingEntities = yield call(
            billingGateway.getBillingEntities,
            operation.id
        );
        yield put(setBillingEntities(billingEntities));
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* createBillableEntity(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.createBillableEntity,
            operation.id,
            action.entity
        );
        let billableEntities = yield call(
            billingGateway.getBillableEntities,
            operation.id
        );
        yield put(setBillableEntities(billableEntities));
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* updateBillableEntity(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.updateBillableEntity,
            operation.id,
            action.entity
        );
        let billableEntities = yield call(
            billingGateway.getBillableEntities,
            operation.id
        );
        yield put(setBillableEntities(billableEntities));
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* getInvoices(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        let invoices = yield call(billingGateway.getInvoices, operation.id);
        yield put(setInvoices(invoices));
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* getLastInvoiceDate(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        let lastInvoiceDate = yield call(
            billingGateway.getLastInvoiceDate,
            operation.id,
            action.billingEntityId,
            action.billableEntityId
        );
        yield put(setLastInvoiceDate(lastInvoiceDate));
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* downloadInvoice(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const { operationId, invoiceId, language } = action;
        const url = yield call(
            billingGateway.getInvoiceDownloadURL,
            operationId,
            invoiceId,
            language
        );
        window.open(url);
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* getBillingPlans(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        let billingPlans = yield call(
            billingGateway.getBillingPlans,
            operation.id
        );
        yield put(setBillingPlans(billingPlans));
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* createBillingPlan(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.createBillingPlan,
            operation.id,
            action.entity
        );
        let billingPlans = yield call(
            billingGateway.getBillingPlans,
            operation.id
        );
        yield put(setBillingPlans(billingPlans));
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* updateBillingPlan(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.updateBillingPlan,
            operation.id,
            action.entity
        );
        let billingPlans = yield call(
            billingGateway.getBillingPlans,
            operation.id
        );
        yield put(setBillingPlans(billingPlans));
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* getBillingSubscriptions(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        let billingSubscriptions = yield call(
            billingGateway.getBillingSubscriptions,
            operation.id
        );
        yield put(setBillingSubscriptions(billingSubscriptions));
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* createBillingSubscription(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.createBillingSubscription,
            operation.id,
            action.entity
        );
        let billingSubscriptions = yield call(
            billingGateway.getBillingSubscriptions,
            operation.id
        );
        yield put(setBillingSubscriptions(billingSubscriptions));
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

function* updateBillingSubscription(action) {
    try {
        const billingGateway = yield getContext('billingNewGateway');
        const operation = yield select(selectSelectedOperation);
        yield call(
            billingGateway.updateBillingSubscription,
            operation.id,
            action.entity
        );
        let billingSubscriptions = yield call(
            billingGateway.getBillingSubscriptions,
            operation.id
        );
        yield put(setBillingSubscriptions(billingSubscriptions));
    } catch (error) {
        console.log(error);
        yield put(
            setError({
                status: error.response && error.response.status,
                message: error.message,
            })
        );
    }
}

export function* getBillableEntitiesSaga() {
    yield takeLatest(GET_BILLABLE_ENTITIES, getBillableEntities);
}

export function* getBillingEntitiesSaga() {
    yield takeLatest(GET_BILLING_ENTITIES, getBillingEntities);
}

export function* createBillingEntitySaga() {
    yield takeLatest(CREATE_BILLING_ENTITY, createBillingEntity);
}

export function* updateBillingEntitySaga() {
    yield takeLatest(UPDATE_BILLING_ENTITY, updateBillingEntity);
}

export function* createBillableEntitySaga() {
    yield takeLatest(CREATE_BILLABLE_ENTITY, createBillableEntity);
}

export function* updateBillableEntitySaga() {
    yield takeLatest(UPDATE_BILLABLE_ENTITY, updateBillableEntity);
}

export function* getInvoicesSaga() {
    yield takeLatest(GET_INVOICES, getInvoices);
}

export function* getLastInvoiceDateSaga() {
    yield takeLatest(GET_LAST_INVOICE_DATE, getLastInvoiceDate);
}

export function* downloadInvoiceSaga() {
    yield takeLatest(DOWNLOAD_INVOICE, downloadInvoice);
}

export function* getBillingPlansSaga() {
    yield takeLatest(GET_BILLING_PLANS, getBillingPlans);
}

export function* createBillingPlanSaga() {
    yield takeLatest(CREATE_BILLING_PLAN, createBillingPlan);
}

export function* updateBillingPlanSaga() {
    yield takeLatest(UPDATE_BILLING_PLAN, updateBillingPlan);
}

export function* getBillingSubscriptionsSaga() {
    yield takeLatest(GET_BILLING_SUBSCRIPTIONS, getBillingSubscriptions);
}

export function* createBillingSubscriptionSaga() {
    yield takeLatest(CREATE_BILLING_SUBSCRIPTION, createBillingSubscription);
}

export function* updateBillingSubscriptionSaga() {
    yield takeLatest(UPDATE_BILLING_SUBSCRIPTION, updateBillingSubscription);
}

const billingSagas = [
    getBillableEntitiesSaga,
    createBillingEntitySaga,
    getBillingEntitiesSaga,
    updateBillingEntitySaga,
    getInvoicesSaga,
    getLastInvoiceDateSaga,
    downloadInvoiceSaga,
    getBillingPlansSaga,
    createBillingPlanSaga,
    updateBillingPlanSaga,
    getBillingSubscriptionsSaga,
    createBillingSubscriptionSaga,
    updateBillingSubscriptionSaga,
    createBillableEntitySaga,
    updateBillableEntitySaga,
];

export default billingSagas;
