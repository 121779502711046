//account reducer
export const INIT_ACCOUNT_INFO = 'INIT_ACCOUNT_INFO';
export const SET_ACCOUNT_INFO = 'SET_ACCOUNT_INFO';
export const GET_ACCOUNT_INFO = 'GET_ACCOUNT_INFO';

//accountOperationBillings reducer
export const INIT_ACCOUNT_OPERATION_BILLINGS =
    'INIT_ACCOUNT_OPERATION_BILLINGS';
export const SET_ACCOUNT_OPERATION_BILLINGS = 'SET_ACCOUNT_OPERATION_BILLINGS';
export const GET_ACCOUNT_OPERATION_BILLINGS = 'GET_ACCOUNT_OPERATION_BILLINGS';

//operation reducer
export const GET_OPERATIONS = 'GET_OPERATIONS';
export const SET_OPERATIONS = 'SET_OPERATIONS';
export const SET_SELECTED_OPERATION = 'SET_SELECTED_OPERATION';
export const INIT_SELECTED_OPERATION = 'INIT_SELECTED_OPERATION';
export const INIT_OPERATIONS = 'INIT_OPERATIONS';
export const GET_OPERATION_PARTICIPANTS = 'GET_OPERATION_PARTICIPANTS';
export const IMPORT_PARTICIPANTS = 'IMPORT_PARTICIPANTS';
export const UPDATE_OPERATION = 'UPDATE_OPERATION';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_SORTING = 'SET_SORTING';
export const INIT_SORTING = 'INIT_SORTING';
export const SET_PARTICIPANT_TYPE = 'SET_PARTICIPANT_TYPE';
export const UPDATE_PRESIGNED_LOGOS = 'UPDATE_PRESIGNED_LOGOS';

export const UPDATE_OPERATION_THEME = 'UPDATE_OPERATION_THEME';

//operationInterlocutor reducer
export const INIT_OPERATION_INTERLOCUTOR = 'INIT_OPERATION_INTERLOCUTOR';
export const SET_OPERATION_INTERLOCUTOR = 'SET_OPERATION_INTERLOCUTOR';
export const GET_OPERATION_INTERLOCUTOR = 'GET_OPERATION_INTERLOCUTOR';

// participant reducer
export const UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT';
export const GET_PRESIGNED_BILLING_LOGO = 'GET_PRESIGNED_BILLING_LOGO';
export const SET_PRESIGNED_BILLING_LOGO = 'SET_PRESIGNED_BILLING_LOGO';

// measurement reducer
export const SET_SELECTED_DATES = 'SET_SELECTED_DATES';
export const GET_RANGE = 'GET_RANGE';
export const SET_RANGE = 'SET_RANGE';
export const SET_IS_QUERYING_DATA_IN_RANGE = 'SET_IS_QUERYING_DATA_IN_RANGE';
export const INIT_SELECTED_DATES = 'INIT_SELECTED_DATES';
export const INIT_RANGE = 'INIT_RANGE';
export const INIT_IS_QUERYING_DATA_IN_RANGE = 'INIT_IS_QUERYING_DATA_IN_RANGE';
export const RANGE_LOADING = 'RANGE_LOADING';
export const SET_CO2_METRICS_DATA = 'SET_CO2_METRICS_DATA';
export const SET_METRICS_DATA = 'SET_METRICS_DATA';
export const GET_METRICS_DATA = 'GET_METRICS_DATA';
export const GET_CONSUMPTION_METRICS_DATA = 'GET_CONSUMPTION_METRICS_DATA';
export const GET_PRODUCTION_METRICS_DATA = 'GET_PRODUCTION_METRICS_DATA';
export const GET_CONSUMER_CONSUMPTION_METRICS_DATA =
    'GET_CONSUMER_CONSUMPTION_METRICS_DATA';
export const GET_PRODUCER_PRODUCTION_METRICS_DATA =
    'GET_PRODUCER_PRODUCTION_METRICS_DATA';
export const GET_PROSUMER_CONSUMPTION_METRICS_DATA =
    'GET_PROSUMER_CONSUMPTION_METRICS_DATA';
export const GET_PRODUCTION_BY_PRODUCERS_METRICS_DATA =
    'GET_PRODUCTION_BY_PRODUCERS_METRICS_DATA';
export const GET_INDIVIDUAL_AUTO_CONSUMPTION_BY_PRODUCERS_METRICS_DATA =
    'GET_INDIVIDUAL_AUTO_CONSUMPTION_BY_PRODUCERS_METRICS_DATA';
export const GET_SURPLUS_BY_PRODUCERS_METRICS_DATA =
    'GET_SURPLUS_BY_PRODUCERS_METRICS_DATA';
export const GET_AUTO_CONSUMPTION_BY_PRODUCERS_METRICS_DATA =
    'GET_AUTO_CONSUMPTION_BY_PRODUCERS_METRICS_DATA';
export const GET_AUTO_CONSUMPTION_BY_CONSUMERS_METRICS_DATA =
    'GET_AUTO_CONSUMPTION_BY_CONSUMERS_METRICS_DATA';
export const GET_CONSUMPTION_BY_CONSUMERS_METRICS_DATA =
    'GET_CONSUMPTION_BY_CONSUMERS_METRICS_DATA';
export const GET_SUPPLIER_CONSUMPTION_BY_PROSUMERS_METRICS_DATA =
    'GET_SUPPLIER_CONSUMPTION_BY_PROSUMERS_METRICS_DATA';
export const SET_CO2_METRICS_LOADING = 'GET_METRICS_LOADING';
export const SET_METRICS_LOADING = 'SET_METRICS_LOADING';
export const SET_CDC_DATA = 'SET_CDC_DATA';
export const GET_CDC_DATA = 'GET_CDC_DATA';
export const GET_CONSUMER_CDC_DATA = 'GET_CONSUMER_CDC_DATA';
export const GET_PRODUCER_CDC_DATA = 'GET_PRODUCER_CDC_DATA';
export const GET_PARTICIPANT_METRICS_DATA = 'GET_PARTICIPANT_METRICS_DATA';
export const SET_CDC_LOADING = 'SET_CDC_LOADING';
export const INIT_MEASUREMENT = 'INIT_MEASUREMENT';
export const SET_DISTRIBUTION_DATA = 'SET_DISTRIBUTION_DATA';
export const GET_DISTRIBUTION_DATA = 'GET_DISTRIBUTION_DATA';
export const SET_DISTRIBUTION_LOADING = 'SET_DISTRIBUTION_LOADING';

// route reducer
export const SET_CRUMBS = 'SET_CRUMBS';
export const INIT_CRUMBS = 'INIT_CRUMBS';
export const SET_ROUTES = 'SET_ROUTES';
export const INIT_ROUTES = 'INIT_ROUTES';

// drawer reducer
export const SET_ITEM = 'SET_ITEM';
export const INIT_DRAWER = 'INIT_DRAWER';

// authentication reducer
export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const INIT_USER = 'INIT_USER';
export const SET_INITIALIZED = 'SET_INITIALIZED';
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const ACCEPT_CGU = 'ACCEPT_CGU';
export const SET_CGU = 'SET_CGU';
export const SET_IS_AUTHENTICATING = 'SET_IS_AUTHENTICATING';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

// functionalities reducer
export const SET_AC_TYPE = 'SET_AC_TYPE';
export const SET_EMISSION = 'SET_EMISSION';

// billing reducer
export const SET_CONFIGS = 'SET_CONFIGS';
export const SET_CONTRACTS = 'SET_CONTRACTS';
export const INIT_BILLING_CONFIG = 'INIT_BILLING_CONFIG';
export const SET_PARTICIPANTS_BILLS = 'SET_PARTICIPANTS_BILLS';
export const CREATE_CONFIG = 'CREATE_CONFIG';
export const REMOVE_CONFIG = 'REMOVE_CONFIG';
export const GET_CONFIGS = 'GET_CONFIGS';
export const GET_BILLS = 'GET_BILLS';
export const UPDATE_BILLS = 'UPDATE_BILLS';
export const EDIT_CONFIG = 'EDIT_CONFIG';
export const CREATE_CONTRACT = 'CREATE_CONTRACT';
export const DOWNLOAD_BILL_DOCUMENTS = 'DOWNLOAD_BILL_DOCUMENTS';
export const SET_DOWNLOAD_LOADING = 'SET_DOWNLOAD_LOADING';
export const DOWNLOAD_BILLING_STATUS = 'DOWNLOAD_BILLING_STATUS';

// billing new reducer
export const GET_BILLING_ENTITIES = 'GET_BILLING_ENTITIES';
export const SET_BILLING_ENTITIES = 'SET_BILLING_ENTITIES';
export const GET_BILLABLE_ENTITIES = 'GET_BILLABLE_ENTITIES';
export const SET_BILLABLE_ENTITIES = 'SET_BILLABLE_ENTITIES';
export const CREATE_BILLING_ENTITY = 'CREATE_BILLING_ENTITY';
export const UPDATE_BILLING_ENTITY = 'UPDATE_BILLING_ENTITY';
export const CREATE_BILLABLE_ENTITY = 'CREATE_BILLABLE_ENTITY';
export const UPDATE_BILLABLE_ENTITY = 'UPDATE_BILLABLE_ENTITY';
export const GET_BILLING_PLANS = 'GET_BILLING_PLANS';
export const SET_BILLING_PLANS = 'SET_BILLING_PLANS';
export const CREATE_BILLING_PLAN = 'CREATE_BILLING_PLAN';
export const UPDATE_BILLING_PLAN = 'UPDATE_BILLING_PLAN';
export const GET_BILLING_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const SET_BILLING_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const CREATE_BILLING_SUBSCRIPTION = 'CREATE_BILLING_SUBSCRIPTION';
export const UPDATE_BILLING_SUBSCRIPTION = 'UPDATE_BILLING_SUBSCRIPTION';
export const GET_INVOICES = 'GET_INVOICES';
export const SET_INVOICES = 'SET_INVOICES';
export const GET_LAST_INVOICE_DATE = 'GET_LAST_INVOICE_DATE';
export const SET_LAST_INVOICE_DATE = 'SET_LAST_INVOICE_DATE';
export const DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE';

// error reducer
export const SET_ERROR = 'SET_ERROR';
export const INIT_ERROR = 'INIT_ERROR';

// notification reducer
export const SET_NOTIF = 'SET_NOTIF';
export const INIT_NOTIF = 'INIT_NOTIF';

// navigation reducer
export const GET_APPMAP = 'GET_APPMAP';
export const SET_APPMAP = 'SET_APPMAP';

// billCheck
export const GET_CONSUMPTION_AND_ECONOMY = 'GET_CONSUMPTION_AND_ECONOMY';
export const SET_CONSUMPTION_AND_ECONOMY = 'SET_CONSUMPTION_AND_ECONOMY';
export const INIT_CONSUMPTION_AND_ECONOMY = 'INIT_CONSUMPTION_AND_ECONOMY';
export const SET_CONSUMPTION_AND_ECONOMY_LOADING =
    'SET_CONSUMPTION_AND_ECONOMY_LOADING';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_CONSUMER_TYPE = 'SET_CONSUMER_TYPE';
export const SET_IS_ON_TRV = 'SET_IS_ON_TRV';
export const SET_CONTRACT_TYPE = 'SET_CONTRACT_TYPE';
export const SET_BASE_KWH_PRICE = 'SET_BASE_KWH_PRICE';
export const SET_PEAK_KWH_PRICE = 'SET_PEAK_KWH_PRICE';
export const SET_OFF_PEAK_KWH_PRICE = 'SET_OFF_PEAK_KWH_PRICE';
export const SET_WINTER_PEAK_KWH_PRICE = 'SET_WINTER_PEAK_KWH_PRICE';
export const SET_WINTER_OFF_PEAK_KWH_PRICE = 'SET_WINTER_OFF_PEAK_KWH_PRICE';
export const SET_SUMMER_PEAK_KWH_PRICE = 'SET_SUMMER_PEAK_KWH_PRICE';
export const SET_SUMMER_OFF_PEAK_KWH_PRICE = 'SET_SUMMER_OFF_PEAK_KWH_PRICE';
export const SET_PEAK_HOUR_START = 'SET_PEAK_HOUR_START';
export const SET_OFF_PEAK_HOUR_START = 'SET_OFF_PEAK_HOUR_START';
export const APPLY_CONFIG = 'APPLY_CONFIG';
export const INIT_PARTICIPANT_FORM = 'INIT_PARTICIPANT_FORM';
export const INIT_GLOBAL_FORM = 'INIT_GLOBAL_FORM';
export const SET_CONTRACT_TYPE_FROM_PARTICIPANT_DATA =
    'SET_CONTRACT_TYPE_FROM_PARTICIPANT_DATA';
export const GET_PRICES_CONFIGS = 'GET_PRICES_CONFIGS';
export const SELECT_PARTICIPANT = 'SELECT_PARTICIPANT';
export const SELECT_ALL_PARTICIPANTS = 'SELECT_ALL_PARTICIPANTS';
export const UNSELECT_PARTICIPANT = 'UNSELECT_PARTICIPANT';
export const UNSELECT_ALL_PARTICIPANTS = 'UNSELECT_ALL_PARTICIPANTS';
export const GET_TRV_PRICE = 'GET_TRV_PRICE';
export const SET_TRV_PRICE = 'SET_TRV_PRICE';

// communication
export const SEND_COMMUNICATION = 'SEND_COMMUNICATION';
export const SET_COMMUNICATION_RESULT = 'SET_COMMUNICATION_RESULT';

// energy allocation
export const GET_ALLOCATION_ALGORITHM_CONFIG =
    'GET_ALLOCATION_ALGORITHM_CONFIG';
export const GET_PARTICIPANTS_ALLOCATION_PARAMETERS =
    'GET_PARTICIPANTS_ALLOCATIONS_PARAMETERS';
export const SET_PERSISTED_ALLOCATION_ALGORITHM_CONFIG_TYPE =
    'SET_PERSISTED_ALLOCATION_ALGORITHM_CONFIG_TYPE';
export const SET_NEW_ALLOCATION_ALGORITHM_TYPE =
    'SET_NEW_ALLOCATION_ALGORITHM_TYPE';
export const SET_NEW_PARTICIPANT_PARAMETER = 'SET_NEW_PARTICIPANT_PARAMETER';
export const SET_PERSISTED_PARTICIPANTS_PARAMETERS =
    'SET_PERSISTED_PARTICIPANTS_PARAMETERS';
export const SET_NEW_PARTICIPANTS_PARAMETERS =
    'SET_NEW_PARTICIPANTS_PARAMETERS';
export const INIT_NEW_ALLOCATION_ALGORITHM_CONFIG =
    'INIT_NEW_ALLOCATION_ALGORITHM_CONFIG';
export const INIT_NEW_PARTICIPANTS_PARAMETERS =
    'INIT_NEW_PARTICIPANTS_PARAMETERS';
export const CREATE_PARTICIPANT_PARAMETER = 'CREATE_PARTICIPANT_PARAMETER';
export const UPDATE_ENERGY_ALLOCATION = 'UPDATE_ENERGY_ALLOCATION';
export const UPDATE_OPERATION_SEASONALITY = 'UPDATE_OPERATION_SEASONALITY';
export const GET_OPERATION_SEASONALITY = 'GET_OPERATION_SEASONALITY';
export const SET_PERSISTED_SEASONALITY = 'SET_PERSISTED_SEASONALITY';

// participant payments
export const CREATE_CREDITOR_DETAIL = 'CREATE_CREDITOR_DETAIL';
export const CREATE_BILLING_ENTITY_CREDITOR = 'CREATE_BILLING_ENTITY_CREDITOR';
export const CREATE_BILLING_ENTITY_MANDATE = 'CREATE_BILLING_ENTITY_MANDATE';
export const CREATE_PARTICIPANT_MANDATES_DETAILS =
    'CREATE_PARTICIPANT_MANDATES_DETAILS';
export const CREATE_MULTIPLE_MANDATES_FOR_PRODUCER =
    'CREATE_MULTIPLE_MANDATES_FOR_PRODUCER';
export const CREATE_MULTIPLE_MANDATES_FOR_BILLING_ENTITY =
    'CREATE_MULTIPLE_MANDATES_FOR_BILLING_ENTITY';
export const DOWNLOAD_SEPA_FILE = 'DOWNLOAD_SEPA_FILE';
export const GET_OPERATION_CREDITORS_DETAILS =
    'GET_OPERATION_CREDITORS_DETAILS';
export const GET_OPERATION_CREDITORS_FOR_BILLING_NEW =
    'GET_OPERATION_CREDITORS_FOR_BILLING_NEW';
export const GET_BILLING_ENTITY_MANDATES = 'GET_BILLING_ENTITY_MANDATES';
export const SET_BILLING_ENTITY_MANDATES = 'SET_BILLING_ENTITY_MANDATES';
export const GET_PRODUCER_MANDATES = 'GET_PRODUCER_MANDATES';
export const SET_PRODUCER_MANDATES = 'SET_PRODUCER_MANDATES';
export const SET_OPERATION_CREDITORS_DETAILS =
    'SET_OPERATION_CREDITORS_DETAILS';

export const UPLOAD_SIBELGA_LOAD_CURVES = 'UPLOAD_SIBELGA_LOAD_CURVES';
export const SET_SIBELGA_LOAD_CURVES_UPLOAD_LOADER =
    'SET_SIBELGA_LOAD_CURVES_UPLOAD_LOADER';
