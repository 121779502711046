import {
    ACCEPT_CGU,
    CREATE_CONTRACT,
    APPLY_CONFIG,
    CHANGE_PASSWORD,
    CREATE_CONFIG,
    CREATE_BILLING_ENTITY_CREDITOR,
    CREATE_BILLING_ENTITY_MANDATE,
    CREATE_MULTIPLE_MANDATES_FOR_BILLING_ENTITY,
    CREATE_MULTIPLE_MANDATES_FOR_PRODUCER,
    CREATE_PARTICIPANT_MANDATES_DETAILS,
    CREATE_PARTICIPANT_PARAMETER,
    CREATE_CREDITOR_DETAIL,
    DOWNLOAD_BILL_DOCUMENTS,
    DOWNLOAD_BILLING_STATUS,
    DOWNLOAD_SEPA_FILE,
    EDIT_CONFIG,
    GET_ACCOUNT_INFO,
    GET_ACCOUNT_OPERATION_BILLINGS,
    GET_ALLOCATION_ALGORITHM_CONFIG,
    GET_APPMAP,
    GET_AUTO_CONSUMPTION_BY_CONSUMERS_METRICS_DATA,
    GET_AUTO_CONSUMPTION_BY_PRODUCERS_METRICS_DATA,
    GET_BILLS,
    GET_CDC_DATA,
    GET_CONFIGS,
    GET_CONSUMER_CDC_DATA,
    GET_CONSUMER_CONSUMPTION_METRICS_DATA,
    GET_CONSUMPTION_AND_ECONOMY,
    GET_CONSUMPTION_BY_CONSUMERS_METRICS_DATA,
    GET_CONSUMPTION_METRICS_DATA,
    GET_DISTRIBUTION_DATA,
    GET_INDIVIDUAL_AUTO_CONSUMPTION_BY_PRODUCERS_METRICS_DATA,
    GET_METRICS_DATA,
    GET_OPERATION_INTERLOCUTOR,
    GET_OPERATION_PARTICIPANTS,
    GET_OPERATIONS,
    GET_PARTICIPANT_METRICS_DATA,
    GET_PARTICIPANTS_ALLOCATION_PARAMETERS,
    GET_PRESIGNED_BILLING_LOGO,
    GET_PRICES_CONFIGS,
    GET_PRODUCER_CDC_DATA,
    GET_PRODUCER_PRODUCTION_METRICS_DATA,
    GET_PRODUCTION_BY_PRODUCERS_METRICS_DATA,
    GET_PRODUCTION_METRICS_DATA,
    GET_PROSUMER_CONSUMPTION_METRICS_DATA,
    GET_RANGE,
    GET_SUPPLIER_CONSUMPTION_BY_PROSUMERS_METRICS_DATA,
    GET_SURPLUS_BY_PRODUCERS_METRICS_DATA,
    GET_TRV_PRICE,
    GET_USER,
    INIT_ACCOUNT_INFO,
    INIT_ACCOUNT_OPERATION_BILLINGS,
    INIT_CONSUMPTION_AND_ECONOMY,
    INIT_CRUMBS,
    INIT_DRAWER,
    INIT_GLOBAL_FORM,
    INIT_IS_QUERYING_DATA_IN_RANGE,
    INIT_MEASUREMENT,
    INIT_NEW_ALLOCATION_ALGORITHM_CONFIG,
    INIT_NEW_PARTICIPANTS_PARAMETERS,
    INIT_OPERATION_INTERLOCUTOR,
    INIT_OPERATIONS,
    INIT_PARTICIPANT_FORM,
    INIT_RANGE,
    INIT_ROUTES,
    INIT_SELECTED_DATES,
    INIT_SELECTED_OPERATION,
    INIT_SORTING,
    INIT_USER,
    LOG_IN,
    LOG_OUT,
    RANGE_LOADING,
    REMOVE_CONFIG,
    SELECT_ALL_PARTICIPANTS,
    SELECT_PARTICIPANT,
    SEND_COMMUNICATION,
    SET_AC_TYPE,
    SET_ACCOUNT_INFO,
    SET_ACCOUNT_OPERATION_BILLINGS,
    SET_APPMAP,
    SET_BASE_KWH_PRICE,
    SET_CDC_DATA,
    SET_CDC_LOADING,
    SET_CGU,
    SET_CO2_METRICS_DATA,
    SET_CO2_METRICS_LOADING,
    SET_COMMUNICATION_RESULT,
    SET_CONFIGS,
    SET_CONSUMER_TYPE,
    SET_CONSUMPTION_AND_ECONOMY,
    SET_CONSUMPTION_AND_ECONOMY_LOADING,
    SET_CONTRACT_TYPE,
    SET_CONTRACT_TYPE_FROM_PARTICIPANT_DATA,
    SET_CRUMBS,
    SET_DISTRIBUTION_DATA,
    SET_DISTRIBUTION_LOADING,
    SET_DOWNLOAD_LOADING,
    SET_EMISSION,
    SET_END_DATE,
    SET_ERROR,
    SET_INITIALIZED,
    SET_IS_AUTHENTICATING,
    SET_IS_ON_TRV,
    SET_IS_QUERYING_DATA_IN_RANGE,
    SET_ITEM,
    SET_METRICS_DATA,
    SET_METRICS_LOADING,
    SET_NEW_ALLOCATION_ALGORITHM_TYPE,
    SET_NEW_PARTICIPANT_PARAMETER,
    SET_NEW_PARTICIPANTS_PARAMETERS,
    SET_NOTIF,
    SET_OFF_PEAK_HOUR_START,
    SET_OFF_PEAK_KWH_PRICE,
    SET_OPERATION_INTERLOCUTOR,
    SET_OPERATIONS,
    SET_PARTICIPANT_TYPE,
    SET_PARTICIPANTS_BILLS,
    SET_PEAK_HOUR_START,
    SET_PEAK_KWH_PRICE,
    SET_PERSISTED_ALLOCATION_ALGORITHM_CONFIG_TYPE,
    SET_PERSISTED_PARTICIPANTS_PARAMETERS,
    SET_PRESIGNED_BILLING_LOGO,
    SET_RANGE,
    SET_ROUTES,
    SET_SEARCH,
    SET_SELECTED_DATES,
    SET_SELECTED_OPERATION,
    SET_SORTING,
    SET_START_DATE,
    SET_SUMMER_OFF_PEAK_KWH_PRICE,
    SET_SUMMER_PEAK_KWH_PRICE,
    SET_TRV_PRICE,
    SET_USER,
    SET_WINTER_OFF_PEAK_KWH_PRICE,
    SET_WINTER_PEAK_KWH_PRICE,
    UNSELECT_ALL_PARTICIPANTS,
    UNSELECT_PARTICIPANT,
    UPDATE_BILLS,
    UPDATE_ENERGY_ALLOCATION,
    UPDATE_OPERATION,
    UPDATE_OPERATION_THEME,
    UPDATE_PARTICIPANT,
    UPDATE_PRESIGNED_LOGOS,
    GET_OPERATION_CREDITORS_DETAILS,
    GET_OPERATION_CREDITORS_FOR_BILLING_NEW,
    GET_BILLING_ENTITY_MANDATES,
    SET_BILLING_ENTITY_MANDATES,
    GET_PRODUCER_MANDATES,
    SET_PRODUCER_MANDATES,
    SET_OPERATION_CREDITORS_DETAILS,
    GET_OPERATION_SEASONALITY,
    SET_PERSISTED_SEASONALITY,
    UPDATE_OPERATION_SEASONALITY,
    UPLOAD_SIBELGA_LOAD_CURVES,
    SET_SIBELGA_LOAD_CURVES_UPLOAD_LOADER,
    IMPORT_PARTICIPANTS,
    SET_CONTRACTS,
    INIT_BILLING_CONFIG,
    SET_BILLING_ENTITIES,
    GET_BILLING_ENTITIES,
    UPDATE_BILLING_ENTITY,
    CREATE_BILLING_ENTITY,
    SET_INVOICES,
    GET_INVOICES,
    GET_LAST_INVOICE_DATE,
    SET_LAST_INVOICE_DATE,
    DOWNLOAD_INVOICE,
    UPDATE_BILLING_PLAN,
    CREATE_BILLING_PLAN,
    SET_BILLING_PLANS,
    GET_BILLING_PLANS,
    SET_BILLING_SUBSCRIPTIONS,
    GET_BILLING_SUBSCRIPTIONS,
    CREATE_BILLING_SUBSCRIPTION,
    UPDATE_BILLING_SUBSCRIPTION,
    SET_BILLABLE_ENTITIES,
    GET_BILLABLE_ENTITIES,
    CREATE_BILLABLE_ENTITY,
    UPDATE_BILLABLE_ENTITY,
} from './reducers/constants';
import { MetricNaturesEnum } from '../../../app-config';

// account actions
export function getAccountInfo() {
    return { type: GET_ACCOUNT_INFO };
}

export function initAccountInfo() {
    return { type: INIT_ACCOUNT_INFO };
}

export function setAccountInfo(accountInfo) {
    return { type: SET_ACCOUNT_INFO, accountInfo };
}

// account operation billings actions
export function getAccountOperationBillings() {
    return { type: GET_ACCOUNT_OPERATION_BILLINGS };
}

export function initAccountOperationBillings() {
    return { type: INIT_ACCOUNT_OPERATION_BILLINGS };
}

export function setAccountOperationBillings(operationBillings) {
    return { type: SET_ACCOUNT_OPERATION_BILLINGS, operationBillings };
}

// operation actions
export function getOperations(role) {
    return { type: GET_OPERATIONS, role };
}

export function setOperations(operations) {
    return { type: SET_OPERATIONS, operations };
}

export function setSelectedOperation(selectedOperation) {
    return { type: SET_SELECTED_OPERATION, selectedOperation };
}

export function initSelectedOperation() {
    return { type: INIT_SELECTED_OPERATION };
}

export function initOperations() {
    return { type: INIT_OPERATIONS };
}

export function getOperationParticipants(operation, dateFrom, dateTo) {
    return { type: GET_OPERATION_PARTICIPANTS, operation, dateFrom, dateTo };
}

export function importOperationParticipants(operation, file) {
    return { type: IMPORT_PARTICIPANTS, operation, file };
}

export function updateOperation({ operation, formData }) {
    return { type: UPDATE_OPERATION, operation, formData };
}

export function updateOperationTheme({ operation, theme, formData }) {
    return { type: UPDATE_OPERATION_THEME, operation, theme, formData };
}

export function setSearch(search) {
    return { type: SET_SEARCH, search };
}

export function setSorting(sorting) {
    return { type: SET_SORTING, sorting };
}

export function initSorting() {
    return { type: INIT_SORTING };
}

export function setParticipantType(participantType) {
    return { type: SET_PARTICIPANT_TYPE, participantType };
}

export function updatePresignedLogos(operations) {
    return { type: UPDATE_PRESIGNED_LOGOS, operations };
}

// operationInterlocutor actions
export function initOperationInterlocutor() {
    return { type: INIT_OPERATION_INTERLOCUTOR };
}

export function getOperationInterlocutor(operation) {
    return { type: GET_OPERATION_INTERLOCUTOR, operation };
}

export function setOperationInterlocutor(interlocutor) {
    return { type: SET_OPERATION_INTERLOCUTOR, interlocutor };
}

// measurement actions
export function setSelectedDates(selectedDates) {
    return { type: SET_SELECTED_DATES, selectedDates };
}

export function setIsQueryingDataInRange(isQueryingDataInRange) {
    return { type: SET_IS_QUERYING_DATA_IN_RANGE, isQueryingDataInRange };
}

export function setRange(range) {
    return { type: SET_RANGE, range };
}

export function getRange(participantId) {
    return { type: GET_RANGE, participantId };
}

export function initSelectedDates() {
    return { type: INIT_SELECTED_DATES };
}

export function initIsQueryingDataInRange() {
    return { type: INIT_IS_QUERYING_DATA_IN_RANGE };
}

export function initRange() {
    return { type: INIT_RANGE };
}

export function setRangeLoading(loading) {
    return { type: RANGE_LOADING, loading };
}

export function getCDC() {
    return { type: GET_CDC_DATA };
}

export function setCDC(cdc) {
    return { type: SET_CDC_DATA, cdc };
}

export function getMetrics() {
    return { type: GET_METRICS_DATA };
}

export function getConsumptionMetrics() {
    return { type: GET_CONSUMPTION_METRICS_DATA };
}

export function getProductionMetrics() {
    return { type: GET_PRODUCTION_METRICS_DATA };
}

export function getConsumerConsumptionMetrics(participantId) {
    return {
        type: GET_CONSUMER_CONSUMPTION_METRICS_DATA,
        participantId,
    };
}
export function getProducerProductionMetrics(participantId) {
    return {
        type: GET_PRODUCER_PRODUCTION_METRICS_DATA,
        participantId,
    };
}
export function getProsumerConsumptionMetrics(participantId) {
    return {
        type: GET_PROSUMER_CONSUMPTION_METRICS_DATA,
        participantId,
    };
}

export function getProductionByProducersMetrics() {
    return {
        type: GET_PRODUCTION_BY_PRODUCERS_METRICS_DATA,
    };
}

export function getIndividualAutoConsumptionByProducersMetrics() {
    return {
        type: GET_INDIVIDUAL_AUTO_CONSUMPTION_BY_PRODUCERS_METRICS_DATA,
        metricNature:
            MetricNaturesEnum.INDIVIDUAL_AUTO_CONSUMPTION_BY_PRODUCERS,
    };
}

export function getSurplusByProducersMetrics() {
    return {
        type: GET_SURPLUS_BY_PRODUCERS_METRICS_DATA,
        metricNature: MetricNaturesEnum.SURPLUS_BY_PRODUCERS,
    };
}

export function getAutoConsumptionByProducersMetrics() {
    return {
        type: GET_AUTO_CONSUMPTION_BY_PRODUCERS_METRICS_DATA,
        metricNature: MetricNaturesEnum.AUTO_CONSUMPTION_BY_PRODUCERS,
    };
}

export function getAutoConsumptionByConsumersMetrics() {
    return {
        type: GET_AUTO_CONSUMPTION_BY_CONSUMERS_METRICS_DATA,
        metricNature: MetricNaturesEnum.AUTO_CONSUMPTION_BY_CONSUMERS,
    };
}

export function getConsumptionByConsumersMetrics() {
    return {
        type: GET_CONSUMPTION_BY_CONSUMERS_METRICS_DATA,
        metricNature: MetricNaturesEnum.CONSUMPTION_BY_CONSUMERS,
    };
}

export function getSupplierConsumptionByProsumersMetrics() {
    return {
        type: GET_SUPPLIER_CONSUMPTION_BY_PROSUMERS_METRICS_DATA,
        metricNature: MetricNaturesEnum.SUPPLIER_CONSUMPTION_BY_PROSUMERS,
    };
}

export function setCO2Metrics(metrics) {
    return { type: SET_CO2_METRICS_DATA, metrics };
}

export function setMetrics(metricNature, metrics) {
    return { type: SET_METRICS_DATA, metricNature, metrics };
}

export function setCO2MetricsLoading(loading) {
    return { type: SET_CO2_METRICS_LOADING, loading };
}

export function setMetricsLoading(metricNature, loading) {
    return { type: SET_METRICS_LOADING, metricNature, loading };
}

export function setCDCLoading(loading) {
    return { type: SET_CDC_LOADING, loading };
}

export function initMeasurement() {
    return { type: INIT_MEASUREMENT };
}

export function getConsumerCDC(consumerId) {
    return { type: GET_CONSUMER_CDC_DATA, consumerId };
}

export function getProducerCDC(producerId) {
    return { type: GET_PRODUCER_CDC_DATA, producerId };
}

export function getParticipantMetrics({ participantId, isConsumer }) {
    return { type: GET_PARTICIPANT_METRICS_DATA, participantId, isConsumer };
}

export function getDistribution() {
    return { type: GET_DISTRIBUTION_DATA };
}

export function setDistribution(distribution) {
    return { type: SET_DISTRIBUTION_DATA, distribution };
}

export function setDistributionLoading(loading) {
    return { type: SET_DISTRIBUTION_LOADING, loading };
}

// route actions
export function setCrumbs(crumbs) {
    return { type: SET_CRUMBS, crumbs };
}

export function initCrumbs() {
    return { type: INIT_CRUMBS };
}

export function setRoutes(routes) {
    return { type: SET_ROUTES, routes };
}

export function initRoutes() {
    return { type: INIT_ROUTES };
}

// drawer actions
export function setSelectedItem(item) {
    return { type: SET_ITEM, item };
}

export function initDrawer() {
    return { type: INIT_DRAWER };
}

//authentication actions
export function getUser(history) {
    return { type: GET_USER, history };
}

export function setUser(user) {
    return { type: SET_USER, user };
}

export function initUser() {
    return { type: INIT_USER };
}

export function setInitialized(initialized) {
    return { type: SET_INITIALIZED, initialized };
}

export function logOut(history) {
    return { type: LOG_OUT, history };
}

export function logIn({ history, email, password }) {
    return { type: LOG_IN, history, email, password };
}

export function setCgu(cgu, history) {
    return { type: SET_CGU, cgu, history };
}

export function acceptCgu(cguId, history) {
    return { type: ACCEPT_CGU, cguId, history };
}

export function changePassword(oldPassword, newPassword, history) {
    return { type: CHANGE_PASSWORD, oldPassword, newPassword, history };
}

export function setIsAuthenticating(isAuthenticating) {
    return { type: SET_IS_AUTHENTICATING, isAuthenticating };
}

// participant actions
export function updateParticipant(participant, newParticipant, formData) {
    return { type: UPDATE_PARTICIPANT, participant, newParticipant, formData };
}

export function getPresignedBillingLogo(participant) {
    return { type: GET_PRESIGNED_BILLING_LOGO, participant };
}

export function setPresignedBillingLogo(presignedBillingLogo) {
    return { type: SET_PRESIGNED_BILLING_LOGO, presignedBillingLogo };
}

//functionalities actions
export function setACType(ACType) {
    return { type: SET_AC_TYPE, ACType };
}

export function setEmission(emission) {
    return { type: SET_EMISSION, emission };
}

// billing actions
export function downloadBillDocuments(bills, period) {
    return { type: DOWNLOAD_BILL_DOCUMENTS, bills, period };
}

// billing actions
export function setDownloadLoading(loading) {
    return { type: SET_DOWNLOAD_LOADING, loading };
}

export function getConfigs(configType) {
    return { type: GET_CONFIGS, configType };
}

export function createConfig(config, configType) {
    return { type: CREATE_CONFIG, config, configType };
}

export function setParticipantsConfigs(configs) {
    return { type: SET_CONFIGS, configs };
}

export function setContracts(contracts) {
    return { type: SET_CONTRACTS, contracts };
}
export function initBillingConfig() {
    return { type: INIT_BILLING_CONFIG };
}

export function deleteConfig(configId, configType) {
    return { type: REMOVE_CONFIG, configId, configType };
}

export function editConfig(config, configType, isNewConfig) {
    return { type: EDIT_CONFIG, config, configType, isNewConfig };
}

export function createContract(configId, producerId, consumerId, startDate) {
    return {
        type: CREATE_CONTRACT,
        configId,
        producerId,
        consumerId,
        startDate,
    };
}

export function getBills(configType) {
    return { type: GET_BILLS, configType };
}

export function updateBills(bills) {
    return { type: UPDATE_BILLS, bills };
}

export function setParticipantsBills(bills) {
    return { type: SET_PARTICIPANTS_BILLS, bills };
}

export function downloadBillingStatus(producer, billingPeriodEnd) {
    return { type: DOWNLOAD_BILLING_STATUS, producer, billingPeriodEnd };
}

// Billing new actions

export function setBillableEntities(billableEntities) {
    return { type: SET_BILLABLE_ENTITIES, billableEntities };
}

export function getBillableEntities() {
    return { type: GET_BILLABLE_ENTITIES };
}

export function setBillingEntities(billingEntities) {
    return { type: SET_BILLING_ENTITIES, billingEntities };
}

export function getBillingEntities() {
    return { type: GET_BILLING_ENTITIES };
}

export function createBillingEntity(entity) {
    return { type: CREATE_BILLING_ENTITY, entity };
}

export function updateBillingEntity(entity) {
    return { type: UPDATE_BILLING_ENTITY, entity };
}

export function createBillableEntity(entity) {
    return { type: CREATE_BILLABLE_ENTITY, entity };
}

export function updateBillableEntity(entity) {
    return { type: UPDATE_BILLABLE_ENTITY, entity };
}

export function setInvoices(invoices) {
    return { type: SET_INVOICES, invoices };
}

export function getInvoices() {
    return { type: GET_INVOICES };
}

export function getLastInvoiceDate(billingEntityId, billableEntityId) {
    return { type: GET_LAST_INVOICE_DATE, billingEntityId, billableEntityId };
}

export function setLastInvoiceDate(lastInvoiceDate) {
    return { type: SET_LAST_INVOICE_DATE, lastInvoiceDate };
}

export function downloadInvoice(operationId, invoiceId, language) {
    return { type: DOWNLOAD_INVOICE, operationId, invoiceId, language };
}

export function setBillingPlans(billingPlans) {
    return { type: SET_BILLING_PLANS, billingPlans };
}

export function getBillingPlans() {
    return { type: GET_BILLING_PLANS };
}

export function createBillingPlan(entity) {
    return { type: CREATE_BILLING_PLAN, entity };
}

export function updateBillingPlan(entity) {
    return { type: UPDATE_BILLING_PLAN, entity };
}

export function setBillingSubscriptions(billingSubscriptions) {
    return { type: SET_BILLING_SUBSCRIPTIONS, billingSubscriptions };
}

export function getBillingSubscriptions() {
    return { type: GET_BILLING_SUBSCRIPTIONS };
}

export function createBillingSubscription(entity) {
    return { type: CREATE_BILLING_SUBSCRIPTION, entity };
}

export function updateBillingSubscription(entity) {
    return { type: UPDATE_BILLING_SUBSCRIPTION, entity };
}

// error actions
export function setError({ status, message, variant, persist }) {
    return { type: SET_ERROR, status, message, variant, persist };
}

export function initError() {
    return { type: SET_ERROR };
}

// notif actions
export function setNotif(notif) {
    return { type: SET_NOTIF, notif };
}

export function initNotif() {
    return { type: SET_NOTIF };
}

// navigation actions
export function getAppMap() {
    return { type: GET_APPMAP };
}

export function setAppMap(appMap) {
    return { type: SET_APPMAP, appMap };
}

// billCheck actions
export function getConsumptionAndEconomy() {
    return { type: GET_CONSUMPTION_AND_ECONOMY };
}

export function setConsumptionAndEconomy(consumptionAndEconomy) {
    return { type: SET_CONSUMPTION_AND_ECONOMY, consumptionAndEconomy };
}

export function initConsumptionAndEconomy() {
    return { type: INIT_CONSUMPTION_AND_ECONOMY };
}

export function setConsumptionAndEconomyLoading(loading) {
    return { type: SET_CONSUMPTION_AND_ECONOMY_LOADING, loading };
}

export function setStartDate(startDate) {
    return { type: SET_START_DATE, startDate };
}

export function setEndDate(endDate) {
    return { type: SET_END_DATE, endDate };
}

export function setContractType(contractType) {
    return { type: SET_CONTRACT_TYPE, contractType };
}

export function setConsumerType(consumerType) {
    return { type: SET_CONSUMER_TYPE, consumerType };
}

export function setBasekWhPrice(basekWhPrice) {
    return { type: SET_BASE_KWH_PRICE, basekWhPrice };
}

export function setPeakkWhPrice(peakkWhPrice) {
    return { type: SET_PEAK_KWH_PRICE, peakkWhPrice };
}

export function setOffPeakkWhPrice(offPeakkWhPrice) {
    return { type: SET_OFF_PEAK_KWH_PRICE, offPeakkWhPrice };
}

export function setWinterPeakkWhPrice(winterPeakkWhPrice) {
    return { type: SET_WINTER_PEAK_KWH_PRICE, winterPeakkWhPrice };
}

export function setWinterOffPeakkWhPrice(winterOffPeakkWhPrice) {
    return { type: SET_WINTER_OFF_PEAK_KWH_PRICE, winterOffPeakkWhPrice };
}

export function setSummerPeakkWhPrice(summerPeakkWhPrice) {
    return { type: SET_SUMMER_PEAK_KWH_PRICE, summerPeakkWhPrice };
}

export function setSummerOffPeakkWhPrice(summerOffPeakkWhPrice) {
    return { type: SET_SUMMER_OFF_PEAK_KWH_PRICE, summerOffPeakkWhPrice };
}

export function setIsOnTRV(isOnTRV) {
    return { type: SET_IS_ON_TRV, isOnTRV };
}

export function setPeakHourStart(peakHourStart) {
    return { type: SET_PEAK_HOUR_START, peakHourStart };
}

export function setOffPeakHourStart(offPeakHourStart) {
    return { type: SET_OFF_PEAK_HOUR_START, offPeakHourStart };
}

export function applyConfig(participants, participantForm) {
    return { type: APPLY_CONFIG, participants, participantForm };
}

export function initParticipantForm(participantForm) {
    return { type: INIT_PARTICIPANT_FORM, participantForm };
}

export function initGlobalForm() {
    return { type: INIT_GLOBAL_FORM };
}

export function setContractTypeFromParticipantData(id, consumerType) {
    return { type: SET_CONTRACT_TYPE_FROM_PARTICIPANT_DATA, consumerType, id };
}

export function getPricesConfigs() {
    return { type: GET_PRICES_CONFIGS };
}

export function selectParticipant(id) {
    return { type: SELECT_PARTICIPANT, id };
}

export function selectAllParticipants(ids) {
    return { type: SELECT_ALL_PARTICIPANTS, ids };
}

export function unselectAllParticipants() {
    return { type: UNSELECT_ALL_PARTICIPANTS };
}

export function unSelectParticipant(id) {
    return { type: UNSELECT_PARTICIPANT, id };
}

export function getTRVPrice() {
    return { type: GET_TRV_PRICE };
}

export function setTRVPrice(trvPrice) {
    return { type: SET_TRV_PRICE, trvPrice };
}

// communication actions
export function sendCommunication({ subject, body, recipients }) {
    return { type: SEND_COMMUNICATION, subject, body, recipients };
}

export function setCommunicationResult({ message, messageType }) {
    return { type: SET_COMMUNICATION_RESULT, message, messageType };
}

// energy allocation actions
export function getAllocationAlgorithmConfig() {
    return { type: GET_ALLOCATION_ALGORITHM_CONFIG };
}

export function getParticipantsAllocationParameters() {
    return { type: GET_PARTICIPANTS_ALLOCATION_PARAMETERS };
}

export function setPersistedAllocationAlgorithmConfigType(config) {
    return { type: SET_PERSISTED_ALLOCATION_ALGORITHM_CONFIG_TYPE, config };
}

export function setNewAllocationAlgorithmType(algorithmType) {
    return { type: SET_NEW_ALLOCATION_ALGORITHM_TYPE, algorithmType };
}

export function initNewAllocationAlgorithmConfig() {
    return { type: INIT_NEW_ALLOCATION_ALGORITHM_CONFIG };
}

export function setPersistedParticipantsParameters(parameters) {
    return { type: SET_PERSISTED_PARTICIPANTS_PARAMETERS, parameters };
}

export function setNewParticipantsParameters(parameters) {
    return { type: SET_NEW_PARTICIPANTS_PARAMETERS, parameters };
}

export function setNewParticipantParameter(consumerId, producerId, value) {
    return {
        type: SET_NEW_PARTICIPANT_PARAMETER,
        consumerId,
        producerId,
        value,
    };
}

export function initNewParticipantsParameters() {
    return { type: INIT_NEW_PARTICIPANTS_PARAMETERS };
}

export function createParticipantParameter(
    consumerId,
    producerId,
    configId,
    value
) {
    return {
        type: CREATE_PARTICIPANT_PARAMETER,
        consumerId,
        producerId,
        configId,
        value,
    };
}

export function GetOperationSeasonality() {
    return { type: GET_OPERATION_SEASONALITY };
}

export function setPersistedSeasonality(seasonality) {
    return { type: SET_PERSISTED_SEASONALITY, seasonality };
}

//

export function createCreditorDetail(
    participantId,
    paymentDetail,
    isUpdate = false
) {
    return {
        type: CREATE_CREDITOR_DETAIL,
        participantId,
        paymentDetail,
        isUpdate,
    };
}
export function createBillingEntityCreditor(
    billingEntityId,
    paymentDetail,
    isUpdate = false
) {
    return {
        type: CREATE_BILLING_ENTITY_CREDITOR,
        billingEntityId,
        paymentDetail,
        isUpdate,
    };
}

export function createBillingEntityMandate(
    billingEntityId,
    mandateDetails,
    isUpdate = false
) {
    return {
        type: CREATE_BILLING_ENTITY_MANDATE,
        billingEntityId,
        mandateDetails,
        isUpdate,
    };
}

export function createParticipantMandatesDetails(
    participantId,
    mandatesDetails,
    isUpdate = false
) {
    return {
        type: CREATE_PARTICIPANT_MANDATES_DETAILS,
        participantId,
        mandatesDetails,
        isUpdate,
    };
}

export function createMultipleMandatesForProducer(producerId, mandatesDetails) {
    return {
        type: CREATE_MULTIPLE_MANDATES_FOR_PRODUCER,
        producerId,
        mandatesDetails,
    };
}

export function createMultipleMandatesForBillingEntity(
    billingEntityId,
    mandatesDetails
) {
    return {
        type: CREATE_MULTIPLE_MANDATES_FOR_BILLING_ENTITY,
        billingEntityId,
        mandatesDetails,
    };
}

export function downloadSepaFile(producer, billingPeriodEnd) {
    return { type: DOWNLOAD_SEPA_FILE, producer, billingPeriodEnd };
}

export function updateEnergyAllocation() {
    return { type: UPDATE_ENERGY_ALLOCATION };
}

export function getBillingEntityMandates(billingEntityId) {
    return { type: GET_BILLING_ENTITY_MANDATES, billingEntityId };
}

export function getProducerMandates(producerId) {
    return { type: GET_PRODUCER_MANDATES, producerId };
}

export function setBillingEntityMandates(mandates) {
    return { type: SET_BILLING_ENTITY_MANDATES, mandates };
}

export function setProducerMandates(mandates) {
    return { type: SET_PRODUCER_MANDATES, mandates };
}

export function getOperationCreditorsDetails() {
    return { type: GET_OPERATION_CREDITORS_DETAILS };
}

export function getOperationCreditorsForBillingNew() {
    return { type: GET_OPERATION_CREDITORS_FOR_BILLING_NEW };
}

export function setOperationCreditorsDetails(creditors) {
    return { type: SET_OPERATION_CREDITORS_DETAILS, creditors };
}

export function updateOperationSeasonality(operationId, seasonality) {
    return { type: UPDATE_OPERATION_SEASONALITY, operationId, seasonality };
}

export function uploadSibelgaLoadCurves(operationId, files) {
    return { type: UPLOAD_SIBELGA_LOAD_CURVES, operationId, files };
}

export function setSibelgaLoadCurvesUploadLoader(isLoading, operationId) {
    return {
        type: SET_SIBELGA_LOAD_CURVES_UPLOAD_LOADER,
        isLoading,
        operationId,
    };
}
